import Form from 'components/Form/form';
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react';

export default function FormContainer(data) {

    const [passwordReset, setPasswordReset] = useState(false)
    const redirectUri = `${process.env.NEXT_PUBLIC_FRONTEND_PATH}/login_callback`
    useEffect(() => {
      if(window.localStorage.getItem("reset_password")!==undefined && window.localStorage.getItem("reset_password")!==null){
        setPasswordReset(true)
        window.localStorage.removeItem("reset_password")
      }
    },[])

    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-500 flex flex-col justify-center pb-40 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-32 w-auto"
            src="/logo/Signin_Color.svg"
            alt="Workflow"
          />
          {passwordReset && 
          <div className="rounded-md bg-green-50 p-4 flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">Password has been reset successfully.</p>
            </div>
            <div className="ml-auto pl-3 -mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={() => {setPasswordReset(false);}}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>}
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-300">
            Sign in to your account
          </h2>
        </div>
  
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="dark:bg-grey-dark bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Form/>
          </div>
        </div>
        
        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md text-md text-green-omc">
          <center>
            <a href={`https://auth.sandpit.dukc.net/forgotPassword?response_type=code&client_id=65st8mdnjtq0llpa6577upjtd2&redirect_uri=${redirectUri}&response_type=code&state=state&scope=openid%20email`}>Forgot Password?</a>
          </center>
        </div>

        <div className="space-y-6">
            <center>
                <a 
                  className="mt-8 sm:w-full sm:max-w-sm flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-omc hover:bg-green-omc-hover focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                  href={`https://auth.sandpit.dukc.net/login?response_type=code&client_id=65st8mdnjtq0llpa6577upjtd2&redirect_uri=${redirectUri}&response_type=code&state=state&scope=openid%20email`}
                >
                  
                  Login using Corporate Authentication
                </a>
            </center>

        </div>
      </div>
    );
  };
